/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "./src/tailwind.css";
import "./src/basic.css";

import { UtmTracker } from "./src/components/tracking/utm-tracker";
window.__utm = new UtmTracker(window.location.href);
